:root {
    --color-bg-third: #fff;
    --color-highlight-lt: #8DC7F6;
}

header div.center,
ul.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

nav {
    padding: 0 1rem;
}

main section {
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%
}

.accent {
    color: var(--color-accent);
}

.highlight {
    color: var(--color-link);
}

.secondary {
    color: var(--color-text-secondary);
}

hr.unset {
    margin: unset;
    margin-bottom: 1rem;
}

h1.unset {
    margin: unset;
}

a.small b, a.small em, a.small i, a.small strong, button.small {
    border-radius: var(--border-radius);
    display: inline-block;
    font-size: small;
    font-weight: 700;
    line-height: var(--line-height);
    margin: .5rem 0;
    padding: .25rem .5rem;
}

i.gg-block {
    margin-bottom:2rem;
    color:var(--color-highlight-lt);
    opacity:0.4;
}

button.accent {
    background-color: var(--color-accent);
    border: 2px solid var(--color-accent);
    color: var(--color-link);
}

footer.foot {
    position: fixed;
    bottom: 0;
    padding:0;
    width: 100%;
    max-width:unset;
    /* Height of the footer*/
    height: 70px;
}

footer.foot p {
    text-align: center;
}

aside i.gg-mail {
    display: inline-block;
}

aside a {
    margin-left: 0.5rem;
    text-decoration: none;
}

aside.noshadow {
  box-shadow: unset;
}

aside.noshadow:hover {
  box-shadow: unset;
}
