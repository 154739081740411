.gg-block {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,4));
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 100%
}
.gg-block::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 2px;
    background: currentColor;
    border-radius: 5px;
    transform: rotate(-45deg);
    top: 5px;
    left: 1px
}
.gg-screen {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 14px;
    border: 2px solid;
    border-radius: 3px;
    margin-top: -4px
}

.gg-screen::after,
.gg-screen::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 10px;
    height: 2px;
    background: currentColor;
    top: 14px;
    left: 4px
}

.gg-screen::before {
    width: 2px;
    height: 6px;
    top: 10px;
    left: 8px
}

.gg-mail,
.gg-mail::after {
    display: block;
    box-sizing: border-box;
    height: 14px;
    border: 2px solid
}

.gg-mail {
    overflow: hidden;
    transform: scale(var(--ggs,1));
    position: relative;
    width: 18px;
    border-radius: 2px
}

.gg-mail::after {
    content: "";
    position: absolute;
    border-radius: 3px;
    width: 14px;
    transform: rotate(-45deg);
    bottom: 3px;
    left: 0
}
